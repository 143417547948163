<template>
  <div class="main">
    <div class="header">
      <div class="left">
        <button
          style="background-color: rgb(16, 39, 46)"
          class="left-button"
          @click="ToMap()"
        >
          返回
        </button>
      </div>
      <p class="title">智 慧 平 台 数 据 驾 驶 舱</p>
      <div class="header-right">
        <div class="time">{{ time }}</div>
        <div class="avatar">
          <el-popover
            placement="top"
            width="160"
            v-model="visible"
            trigger="hover"
          >
            <p style="padding: 10px 0px">确定要退出登录吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="cancelLogout()"
                >取消</el-button
              >
              <el-button type="primary" size="mini" @click="logout()"
                >确定</el-button
              >
            </div>
            <!-- <img src="../assets/home/avatar.png" alt="" slot="reference" /> -->
            <img
              style="width: 2.5rem; border-radius: 50%; border-color: black"
              src="../assets/home/logo.jpg"
              alt=""
              slot="reference"
            />
          </el-popover>
        </div>
      </div>
    </div>
    <div class="content">
      <p class="content-title">模块参数详情</p>
      <div class="content-table">
        <div class="content-main">
          <!-- 电源 -->
          <div class="box">
            <div class="box-title">电流(A)</div>
            <div class="contents">
              <div class="col">
                <span class="col-key">A相电流：</span>
                <span class="col-value">{{
                  fixedNumber(info.currentA, 2)
                }}</span>
              </div>
              <div class="col">
                <span class="col-key">B相电流：</span>
                <span class="col-value">{{
                  fixedNumber(info.currentB, 2)
                }}</span>
              </div>
              <div class="col">
                <span class="col-key">C相电流：</span>
                <span class="col-value">{{
                  fixedNumber(info.currentC, 2)
                }}</span>
              </div>
              <div class="col col-4">
                <span class="col-key"> 合相电流：</span>
                <span class="col-value">{{
                  fixedNumber(info.currentTotal, 2)
                }}</span>
              </div>
            </div>
          </div>
          <!-- 电压 -->
          <div class="box">
            <div class="box-title">电压(V)</div>
            <div class="contents">
              <div class="col">
                <span class="col-key">A相电压：</span>
                <span class="col-value">
                  {{ fixedNumber(info.voltageA, 0) }}</span
                >
              </div>
              <div class="col">
                <span class="col-key">B相电压：</span>
                <span class="col-value">{{ fixedNumber(info.voltageB, 0) }}</span>
              </div>
              <div class="col">
                <span class="col-key">C相电压：</span>
                <span class="col-value">{{ fixedNumber(info.voltageC, 0) }}</span>
              </div>
              <div class="col col-4">
                <span class="col-key"> 合相电压：</span>
                <span class="col-value">{{ fixedNumber(info.voltageTotal, 0) }}</span>
              </div>
            </div>
          </div>
          <!-- 线电压 -->
          <div class="box">
            <div class="box-title">线电压(V)</div>
            <div class="contents">
              <div class="col">
                <span class="col-key">AB线电压：</span>
                <span class="col-value">{{ fixedNumber(info.voltageAb, 0) }}</span>
              </div>
              <div class="col col-middle">
                <span class="col-key">BC线电压：</span>
                <span class="col-value">{{ fixedNumber(info.voltageBc, 0) }}</span>
              </div>
              <div class="col col-middle">
                <span class="col-key">CA线电压：</span>
                <span class="col-value">{{ fixedNumber(info.voltageCa, 0) }}</span>
              </div>
              <div class="col col-4">
                <span class="col-key"> 合相线电压：</span>
                <span class="col-value"> {{ fixedNumber(info.voltageTotalLine, 0) }}</span>
              </div>
            </div>
          </div>
          <!-- 无功功率 -->
          <div class="box">
            <div class="box-title">无功功率(W)</div>
            <div class="contents">
              <div class="col">
                <span class="col-key">A相无功功率：</span>
                <span class="col-value"> {{ fixedNumber(info.reactivePowerA, 3) }}</span>
              </div>
              <div class="col col-middle">
                <span class="col-key">B相无功功率：</span>
                <span class="col-value">{{ fixedNumber(info.reactivePowerB, 3) }}</span>
              </div>
              <div class="col col-middle">
                <span class="col-key">C相无功功率：</span>
                <span class="col-value">{{ fixedNumber(info.reactivePowerC, 3) }}</span>
              </div>
              <div class="col col-4">
                <span class="col-key"> 合相无功功率：</span>
                <span class="col-value">{{ fixedNumber(info.reactivePowerTotal, 3) }}</span>
              </div>
            </div>
          </div>
          <!-- 有功功率 -->
          <div class="box">
            <div class="box-title">有功功率(W)</div>
            <div class="contents">
              <div class="col">
                <span class="col-key">A相有功功率：</span>
                <span class="col-value">{{ fixedNumber(info.activePowerA, 3) }}</span>
              </div>
              <div class="col col-middle">
                <span class="col-key">B相有功功率：</span>
                <span class="col-value">{{ fixedNumber(info.activePowerB, 3) }}</span>
              </div>
              <div class="col col-middle">
                <span class="col-key">C相有功功率：</span>
                <span class="col-value">{{ fixedNumber(info.activePowerC, 3) }}</span>
              </div>
              <div class="col col-4">
                <span class="col-key"> 合相有功功率：</span>
                <span class="col-value">{{ fixedNumber(info.activePowerTotal, 3) }}</span>
              </div>
            </div>
          </div>
          <!-- 视在功率 -->
          <div class="box">
            <div class="box-title">视在功率(W)</div>
            <div class="contents">
              <div class="col">
                <span class="col-key">A相视在功率：</span>
                <span class="col-value">{{ fixedNumber(info.apparentPowerA, 3) }}</span>
              </div>
              <div class="col col-middle">
                <span class="col-key">B相视在功率：</span>
                <span class="col-value">{{ fixedNumber(info.apparentPowerB, 3) }}</span>
              </div>
              <div class="col col-middle">
                <span class="col-key">C相视在功率：</span>
                <span class="col-value">{{ fixedNumber(info.apparentPowerC, 3) }}</span>
              </div>
              <div class="col col-4">
                <span class="col-key"> 合相视在功率：</span>
                <span class="col-value">{{ fixedNumber(info.apparentPowerTotal, 3) }}</span>
              </div>
            </div>
          </div>
          <!-- 功率因数 -->
          <div class="box">
            <div class="box-title">功率因数(PF)</div>
            <div class="contents">
              <div class="col">
                <span class="col-key">A相功率因数：</span>
                <span class="col-value">{{ info.powerFactorA }}</span>
              </div>
              <div class="col col-middle">
                <span class="col-key">B相功率因数：</span>
                <span class="col-value">{{ info.powerFactorB }}</span>
              </div>
              <div class="col col-middle">
                <span class="col-key">C相功率因数：</span>
                <span class="col-value">{{ info.powerFactorC }}</span>
              </div>
              <div class="col col-4">
                <span class="col-key"> 合相功率因数：</span>
                <span class="col-value">{{ info.powerFactorTotal }}</span>
              </div>
            </div>
          </div>
          <!-- 有功电能 -->
          <div class="box">
            <div class="box-title">有功电能(kWh)</div>
            <div class="contents">
              <div class="col">
                <span class="col-key">A相有功电能：</span>
                <span class="col-value">{{ fixedNumber(info.activeEnergyA, 1) }}</span>
              </div>
              <div class="col col-middle">
                <span class="col-key">B相有功电能：</span>
                <span class="col-value">{{ fixedNumber(info.activeEnergyB, 1) }}</span>
              </div>
              <div class="col col-middle">
                <span class="col-key">C相有功电能：</span>
                <span class="col-value">{{ fixedNumber(info.activeEnergyC, 1) }}</span>
              </div>
              <div class="col col-4">
                <span class="col-key"> 合相有功电能：</span>
                <span class="col-value">{{ fixedNumber(info.activeEnergyTotal, 1) }}</span>
              </div>
            </div>
          </div>
          <!-- 无功电能 -->
          <div class="box">
            <div class="box-title">无功电能(kWh)</div>
            <div class="contents">
              <div class="col">
                <span class="col-key">A相无功电能：</span>
                <span class="col-value">{{ fixedNumber(info.reactiveEnergyA, 1) }}</span>
              </div>
              <div class="col col-middle">
                <span class="col-key">B相无功电能：</span>
                <span class="col-value">{{ fixedNumber(info.reactiveEnergyB, 1) }}</span>
              </div>
              <div class="col col-middle">
                <span class="col-key">C相无功电能：</span>
                <span class="col-value">{{ fixedNumber(info.reactiveEnergyC, 1) }}</span>
              </div>
              <div class="col col-4">
                <span class="col-key"> 合相无功电能：</span>
                <span class="col-value">{{ fixedNumber(info.reactiveEnergyTotal, 1) }}</span>
              </div>
            </div>
          </div>
          <!-- 其他 -->
          <div class="box">
            <div class="box-title">其他</div>
            <div class="contents">
              <div class="col">
                <span class="col-key">频率：</span>
                <span class="col-value">{{ fixedNumber(info.frequency,2) }}</span>
              </div>
              <div class="col">
                <span class="col-key">温度：</span>
                <span class="col-value">{{ fixedNumber(info.temperature,0) }}</span>
              </div>
              <div class="col">
                <span class="col-key">湿度：</span>
                <span class="col-value">{{ fixedNumber(info.humidity,2)  }}</span>
              </div>
              <div class="col">
                <span class="col-key"> 触点测温：</span>
                <span class="col-value">{{ fixedNumber(info.contactTemperature,0) }}</span>
              </div>
              <div class="col">
                <span class="col-key">零序电流：</span>
                <span class="col-value">{{ fixedNumber(info.zeroSequenceCurrent,2) }}</span>
              </div>
              <div class="col">
                <span class="col-key">停电或单相掉电：</span>
                <span class="col-value">{{ info.powerCut ? "是" : "否" }}</span>
              </div>
              <div class="col">
                <span class="col-key">总开关状态：</span>
                <span class="col-value">{{
                  info.switchStatus ? "开启" : "关闭"
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="content-table-footer"></div>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  data() {
    return {
      time: "",
      setTime: "",
      visible: false,
      info: {},
    };
  },
  created() {
    let that = this;
    // @ts-ignore
    this.setTime = setInterval(() => {
      that.Date();
    }, 1000);
  },
  mounted() {
    this.initDevTable();
  },
  methods: {
    // 保留几位小数的方法
    fixedNumber(number, i) {
      return Number(number).toFixed(i);
    },
    // 初始化参数信息
    initDevTable() {
      let param = {
        id: localStorage.getItem("ModelDetailId"),
        // deviceId: localStorage.getItem("ModelDetailDeviceId"),
      };
      this.$get("/moduleLowVoltagePower/load", param)
        .then((res) => {
          console.log(res);
          this.info = res.data;
        })
        .catch(() => {
          console.log("异常");
        });
    },

    // 返回按钮
    ToMap() {
      this.$router.push("/ModelList");
    },
    // 获取时间戳
    Date() {
      const nowDate = new Date();
      const date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate(),
        hours: nowDate.getHours(),
        minutes: nowDate.getMinutes(),
        seconds: nowDate.getSeconds(),
      };
      const newmonth = date.month >= 10 ? date.month : "0" + date.month;
      const newday = date.date >= 10 ? date.date : "0" + date.date;
      const newHours = date.hours >= 10 ? date.hours : "0" + date.hours;
      const newminutes = date.minutes >= 10 ? date.minutes : "0" + date.minutes;
      const newseconds = date.seconds >= 10 ? date.seconds : "0" + date.seconds;

      this.time =
        date.year +
        "年" +
        newmonth +
        "月" +
        newday +
        "日" +
        newHours +
        ":" +
        newminutes +
        ":" +
        newseconds;
    },
    // 退出登录
    logout() {
      this.$get("/user/logout")
        .then((res) => {
          if (res.code == 1) {
            this.$router.push("/Login");
            this.$message({
              type: "success",
              message: "登出成功!",
            });
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    cancelLogout() {
      this.visible = false;
      this.$message({
        type: "info",
        message: "已取消登出",
      });
    },
  },
  beforeDestroy() {
    // 清除定时器
    if (this.setTime) {
      clearInterval(this.setTime);
    }
  },
};
</script>
  
  <style lang="scss" scoped>
//滚动条的宽度
::v-deep ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #e4e4e4;
  border-radius: 3px;
}

//滚动条的滑块
::v-deep ::-webkit-scrollbar-thumb {
  background-color: #033d3d !important;
  border-radius: 3px;
}
.main {
  background: url("../assets/drive/big-screen-backimage.svg");
  margin: 0px; //外边距0
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  zoom: 1;
  background-color: #07080e;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
  color: #fff;
  overflow: auto;
  .header {
    display: flex;
    .left {
      position: absolute;
      top: 3.5vh;
      left: 1.563vw;
      display: flex;
      align-items: center;

      .left-button {
        display: inline-block;
        height: 4vh;
        line-height: 4vh;
        border: 1px solid rgb(11, 166, 167);
        box-shadow: inset 0px 1px 17px rgb(11, 166, 167);
        // background: #000;
        color: rgb(195, 226, 249);
        font-size: 1.04vw;
        border-radius: 7px;
        padding: 0 0.52vw;
        white-space: nowrap;
        box-sizing: border-box;
      }

      .area {
        // width: 28vw;
        display: flex;
        margin-left: 10px;

        .area-title {
          color: rgb(195, 226, 249);
          font-family: PingFang SC;
          font-size: 1.14vw;
          letter-spacing: 0px;
          text-align: center;
          margin: auto 5px;
          vertical-align: middle;
        }

        .area-select {
          height: 4vh;
          box-sizing: border-box;

          select {
            outline: none;
            appearance: none;
            /* 去掉默认图标 */
            -moz-appearance: none;
            /* Firefox */
            -webkit-appearance: none;
            background: url("../assets/drive/drop-box.png") no-repeat scroll 95%
              50% transparent;
            background-size: 0.781vw;
            width: 5.5vw;
            height: 4vh;
            box-sizing: border-box;
            padding: 0 10px;
            font-size: 1.04vw;
            border: 1px solid rgb(11, 166, 167);
            box-shadow: inset 0px 1px 17px rgb(11, 166, 167);
            color: rgb(195, 226, 249);
            font-family: PingFang SC;
            border-radius: 7px;
          }
        }
      }
    }

    .title {
      width: 100%;
      text-align: center;
      // margin-left: -0.5vw;
      background: linear-gradient(
        180deg,
        rgba(238, 238, 238, 1),
        rgba(113, 244, 236, 1)
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      // text-fill-color: transparent;
      font-family: PingFang SC;
      font-size: 2.4vw;
      cursor: pointer;
    }

    .header-right {
      position: absolute;
      right: 1.563vw;
      top: 3.5vh;
      width: 25%;
      display: flex;
      align-items: center;

      .time {
        // margin-left: 20px;
        width: 80vw;
        text-align: right;
        color: rgb(195, 226, 249);
        font-family: PingFang;
        font-size: 1.04vw;
        line-height: 26px;
        letter-spacing: 0px;
        cursor: pointer;
      }

      .avatar {
        margin: 0 0 0 15px;
        width: 10vw;
      }
    }
  }
  .content {
    margin: 6vh 1.563vw;
    font-size: 1.302vw;

    .content-title {
      margin-bottom: 10px;
      cursor: pointer;
    }

    // 详情展示
    .content-main {
      height: 64vh;
      overflow-y: auto;
      overflow-x: hidden;
      // display: flex;
      // flex-wrap: wrap;
      .box {
        margin: 0 2vw 10px;

        .box-title {
          font-size: 1.3vw;
          margin-left: 10px;
          cursor: pointer;
          color: rgb(41, 175, 166);
        }
        .contents {
          display: flex;
          // justify-content: space-between;
          flex-wrap: wrap;
          margin: 10px 0;
          background: #152930;
          padding: 10px;

          font-size: 1.25vw;
          .col {
            width: 25%;
            line-height: 30px;
            span {
              cursor: pointer;
            }
          }
        }
      }
    }

    // 设备控制按钮
    .device-btn {
      display: inline-block;
      height: 4vh;
      line-height: 4vh;
      border: 1px solid rgb(11, 166, 167);
      box-shadow: inset 0px 1px 17px rgb(11, 166, 167);
      background: #000;
      color: rgb(195, 226, 249);
      font-size: 1.04vw;
      border-radius: 7px;
      padding: 0 0.52vw;
      white-space: nowrap;
      box-sizing: border-box;
    }

    .content-table {
      position: relative;
      border: 2px solid rgba(98, 255, 246, 0.1);
      padding: 10px 10px 50px;
      // box-sizing: border-box;

      background: linear-gradient(
        180deg,
        rgba(19, 32, 36, 1),
        rgba(98, 255, 246, 0.1)
      );
      margin-bottom: 30px;

      &::before {
        display: block;
        content: "";
        height: 0.75rem;
        position: absolute;
        left: 0px;
        top: -1px;
        border-left: 2px solid rgb(35, 238, 231);
        border-top: 2px solid rgb(35, 238, 231);
      }

      &::after {
        display: block;
        content: "";
        height: 0.75rem;
        position: absolute;
        right: 0px;
        top: -1px;
        border-right: 2px solid rgb(35, 238, 231);
        border-bottom: 2px solid rgb(35, 238, 231);
      }

      .content-table-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        &::before {
          display: block;
          content: "";
          height: 0.75rem;
          position: absolute;
          left: 0px;
          bottom: -1px;
          border-left: 2px solid rgb(35, 238, 231);
          border-bottom: 2px solid rgb(35, 238, 231);
        }

        &::after {
          display: block;
          content: "";
          height: 0.75rem;
          position: absolute;
          right: 0px;
          bottom: -1px;
          border-right: 2px solid rgb(35, 238, 231);
          border-bottom: 2px solid rgb(35, 238, 231);
        }
      }
    }
  }
}
</style>
 
  